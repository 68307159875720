import EventForm from "components/event-form/EventForm";
import { useEvents } from "hooks";
import { useState } from "react";

const EditEventFormWrapper = ({ onEventsChanged }) => {
  // eslint-disable-next-line
  const [events, _, forceReload] = useEvents();

  const [selectedEvent, setSelectedEvent] = useState(-1);
  return (
    <>
      <div className="flexbox-item">
        <form className="max-w-sm mx-auto">
          <select
            id="events"
            className="text-sm rounded-lg block w-full p-2.5"
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
          >
            <option value={-1}>Auswählen </option>
            {events.map((formEvent, index) => (
              <option key={index} value={index}>
                {formEvent.title}: {formEvent.date}
              </option>
            ))}
          </select>
        </form>
      </div>
      <div className="flexbox-item">
        {selectedEvent !== -1 && !!selectedEvent && (
          <EventForm
            key={selectedEvent}
            event={{ ...events[selectedEvent] }}
            onEventsChanged={() => {
              setSelectedEvent(-1);
              forceReload();
              onEventsChanged();
            }}
          />
        )}
      </div>
    </>
  );
};

export default EditEventFormWrapper;
