import { useState } from "react";
import "./EditEventModal.css";
import Modal from "react-modal";
import EventForm from "components/event-form/EventForm";
import EditEventFormWrapper from "components/edit-event-forn-wrapper/EditEventFormWrapper";

const EditEventsModal = ({ isModalOpen, closeModal, onEventsChanged }) => {
  const [openTab, setOpenTab] = useState("edit");
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() =>
        window.confirm("Möchten Sie die Bearbeitung beenden?") && closeModal()
      }
      style={{}}
      contentLabel="Veranstaltungen editieren"
    >
      <div className="flexbox-wrapper">
        <div className="tabs-flexbox-wrapper flexbox-item pb-0">
          <h1
            className={`flexbox-item clickable pb-0 ${openTab === "edit" ? "activeTab" : ""}`}
            onClick={(e) => setOpenTab("edit")}
          >
            Veranstaltungen editieren
          </h1>

          <h1
            className={`flexbox-item clickable pb-0 ${openTab === "add" ? "activeTab" : ""}`}
            onClick={(e) => setOpenTab("add")}
          >
            Neue Veranstaltung anlegen
          </h1>
        </div>
        <div className="flexbox-item">
          <hr />
          <br />
        </div>

        <div className="flexbox-item">
          {openTab === "add" && <EventForm onEventsChanged={onEventsChanged} />}
        </div>
        <div className="flexbox-item">
          {openTab === "edit" && (
            <EditEventFormWrapper onEventsChanged={onEventsChanged} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditEventsModal;
