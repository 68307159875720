import { useEffect, useState } from "react";

const eventsURL =
  "https://sqqacqzzd2e67xcnu7o7uwj76e0sqkmm.lambda-url.eu-central-1.on.aws/";

const useEvents = (defaultValue = []) => {
  const [events, setEvents] = useState(defaultValue);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadEvents();
  }, [setEvents]);

  const loadEvents = () => {
    fetch(eventsURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      response.json().then((json) => setEvents(json));
    }).catch(e => setError(e));
  };

  return [events, error, loadEvents];
};

export default useEvents;
