import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  WhoWeAre,
  Join,
  Contact,
  NoPage,
  Events,
  Rules,
  Impressum,
} from "pages";
import Layout from "./Layout";

export default function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<WhoWeAre />} />
            <Route path="rules" element={<Rules />} />

            <Route path="events" element={<Events />} />

            <Route path="join" element={<Join />} />
            <Route path="contact" element={<Contact />} />
            <Route path="impressum" element={<Impressum />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
