import {  CollapsabelChildren } from "components";
import { useEffect, useState } from "react";
import { useEvents } from "hooks";
import EditEventsModal from "components/edit-event-modal/EditEventModal";
import EventEntryList from "components/event-entry-list/EventEntryList";

const convertGermanDateToMS = (germanDate) => {
  const splitDate = germanDate.date.split(".");
  return Date.parse(`${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`);
};

const Events = () => {
  // eslint-disable-next-line
  const [events, _, reloadEvents] = useEvents();
  const [pastEvents, setPastEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [downKeys, setDownKeys] = useState(new Set());

  useEffect(() => {
    document.addEventListener("keydown", (e) =>
      setDownKeys((currentDownKeys) => new Set(currentDownKeys.add(e.key))),
    );

    document.addEventListener("keyup", (e) =>
      setDownKeys((currentDownKeys) => {
        currentDownKeys.delete(e.key);
        return new Set(currentDownKeys);
      }),
    );
  }, [setDownKeys]);

  useEffect(() => {
    if (downKeys.has("Ä") && downKeys.has("Shift") && downKeys.has("Control")) {
      setModalOpen(true);
    }
  }, [downKeys, setModalOpen]);

  useEffect(() => {
    let tempCurrentEvents = [];
    let tempPastEvents = [];
    const currentDate = Date.now();
    events
      .sort((a, b) => convertGermanDateToMS(a) - convertGermanDateToMS(b))
      .forEach((element) => {
        if (convertGermanDateToMS(element) > currentDate) {
          tempCurrentEvents.push(element);
        } else {
          tempPastEvents.push(element);
        }
      });
    setCurrentEvents(tempCurrentEvents);
    setPastEvents(tempPastEvents);
  }, [events]);

  return (
    <div>
      <div className="py-8"></div>
      <CollapsabelChildren title={"Aktuelle Veranstaltungen"}>
        <EventEntryList eventEntries={currentEvents} />
      </CollapsabelChildren>
      <div className="py-4"></div>
      <CollapsabelChildren title={"Vergangene Veranstaltungen"}>
        <EventEntryList eventEntries={pastEvents} />
      </CollapsabelChildren>
      <div className="py-8"></div>
      <EditEventsModal
        isModalOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
        onEventsChanged={reloadEvents}
      ></EditEventsModal>
    </div>
  );
};

export default Events;
