const eventsURL =
  "https://sqqacqzzd2e67xcnu7o7uwj76e0sqkmm.lambda-url.eu-central-1.on.aws/";

const changeEvents = async (operation, event) => {
  const password = window.prompt("Bitte Passwort eingeben");

  try {
    const response = await fetch(eventsURL, {
      method: operation,
      body: JSON.stringify({ password: password, event: event }),
    });
    const responseBody = await response.json();
    alert("Änderung gespeichert!");
    return responseBody;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default changeEvents;
