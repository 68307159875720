import EventEntry from "components/event-entry/EventEntry";
import LabeledInput from "components/labeled-input/labeled-input";
import { useState } from "react";
import changeEvents from "utils/changeEvents";
import { convertToGermanDate, parseGermanDate } from "utils/convert-dates";

const EventForm = ({ event, onEventsChanged = () => {} }) => {
  // eslint-disable-next-line
  const [isNew, _] = useState(!event);
  const [isValidEvent, setIsValidEvent] = useState(false);
  const [formEvent, setFormEvent] = useState(
    !!event
      ? {
          ...event,
          unformattedDate: parseGermanDate(event.date),
        }
      : {},
  );

  const updateFormEvent = (updatePart) =>
    setFormEvent((oldFormEvent) => ({ ...oldFormEvent, ...updatePart }));

  const submitEvent = async () => {
    try {
      await changeEvents(isNew ? "PUT" : "POST", formEvent);
      onEventsChanged();

      setFormEvent((oldEventData) => {
        for (let key of Object.keys(oldEventData)) {
          oldEventData[key] = "";
        }
        return { ...oldEventData };
      });
    } catch (error) {
      alert("Ein Fehler ist aufgetreten.");
    }
  };

  const resetEvent = () => {
    setFormEvent((oldEventData) => {
      if (!!event) {
        return {
          ...event,
          unformattedDate: parseGermanDate(event.date),
        };
      } else {
        for (let key of Object.keys(oldEventData)) {
          oldEventData[key] = "";
        }
        return { ...oldEventData };
      }
    });
  };

  const deleteEvent = async () => {
    try {
      await changeEvents("DELETE", formEvent);
      onEventsChanged();
      setFormEvent((oldEventData) => {
        for (let key of Object.keys(oldEventData)) {
          oldEventData[key] = "";
        }
        return { ...oldEventData };
      });
    } catch (error) {
      alert("Ein Fehler ist aufgetreten.");
    }
  };
  return (
    <div className="flexbox-wrapper">
      <div className="flexbox-item">
        <h2>Eingabe</h2>
        <hr />
        <br />
        <div>
          <LabeledInput
            type="text"
            name="Titel"
            onChange={(e) =>
              updateFormEvent({
                title: e.target.value,
              })
            }
            value={formEvent.title}
          />
          <LabeledInput
            type="text"
            name="Ort"
            onChange={(e) =>
              updateFormEvent({
                location: e.target.value,
              })
            }
            value={formEvent.location}
          />{" "}
          <br />
          <LabeledInput
            type="date"
            name="Datum"
            onChange={(e) =>
              updateFormEvent({
                date: convertToGermanDate(e.target.value),
                unformattedDate: e.target.value,
              })
            }
            value={formEvent.unformattedDate}
          />{" "}
          <br />
          <LabeledInput
            type="time"
            name="(Start) Uhrzeit"
            onChange={(e) =>
              updateFormEvent({
                startTime: e.target.value,
              })
            }
            value={formEvent.startTime}
          />{" "}
          <LabeledInput
            type="time"
            name="opt. Enduhrzeit"
            onChange={(e) => updateFormEvent({ endTime: e.target.value })}
            value={formEvent.endTime}
          />{" "}
          <br />
          <LabeledInput
            type="textarea"
            name="Beschreibung"
            onChange={(e) =>
              updateFormEvent({
                description: e.target.value,
              })
            }
            value={formEvent.description}
          />
        </div>
      </div>
      <div className="flexbox-item mt-4">
        <h2>Vorschau</h2>
        <hr />
        <EventEntry
          {...formEvent}
          onEventValidChanged={(isValid) => setIsValidEvent(isValid)}
        />
      </div>

      <div className="flexbox-item mt-4">
        <div className="tabs-flexbox-wrapper">
          <div className="flexbox-item">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  disabled:bg-blue-300"
              onClick={submitEvent}
              disabled={!isValidEvent}
            >
              Speichern
            </button>
          </div>
          <div className="flexbox-item">
            <button
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded  disabled:bg-yellow-300"
              onClick={resetEvent}
            >
              Eingaben Zurücksetzen
            </button>
          </div>
          <div className="flexbox-item">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  disabled:bg-red-300"
              onClick={deleteEvent}
              disabled={isNew}
            >
              Löschen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
