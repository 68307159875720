import EventEntry from "components/event-entry/EventEntry";

const EventEntryList = ({ eventEntries }) => {
  return (
    <>
      {eventEntries &&
        eventEntries.map((event) => (
          <div key={event.date + event.startTime}>
            <EventEntry
              startTime={event.startTime}
              date={event.date}
              location={event.location}
              title={event.title}
              endTime={event.endTime}
              description={event.description}
              material={event.material}
            />
            <div className="py-2"></div>
          </div>
        ))}
    </>
  );
};

export default EventEntryList;
