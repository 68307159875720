const convertToGermanDate = (date) => {
  const dateToTransform = new Date(date);

  return dateToTransform.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const parseGermanDate = (germanDateString) => {
  const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
  return pattern.test(germanDateString)
    ? germanDateString.replace(pattern, "$3-$2-$1")
    : germanDateString;
};

export { convertToGermanDate, parseGermanDate };
