const LabeledInput = ({ type, name, onChange, value }) => {
  return (
    <div className="relative">
      <input
        type={type}
        id={"floating_filled_" + name}
        onChange={onChange}
        className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm border-0 border-b-2 border-gray-300 appearance-none peer"
        placeholder=" "
        value={value}
      />
      <label
        htmlFor={"floating_filled_" + name}
        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {name}
      </label>
    </div>
  );
};
export default LabeledInput;
